import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Container from 'react-bootstrap/Container';
import Header from './header';
import Navigation from './Navigation';

function App() {
  return (
      <Container fluid className='App'>
        <Navigation></Navigation>
      </Container>
    );
}

export default App;
